<template>
  <div bg-neutral-100 dark:bg-neutral-200 my-40px px-40px py-32px fubex-rounded-md grid grid-cols-1 lg:grid-cols-3 gap-y-40px gap-x-20px>
    <div flex flex-col gap-16px>
      <h3 heading6 font-700 flex flex-row justify-start items-center gap-8px>
        <UnoIcon class="i-fubex-dollar-circle" text-accent-500 dark:text-accent-300 h-24px w-24px />{{ $t('components.bitbeli.widgetBenefitsCompanies.quickVerification') }}
      </h3>
      <p>{{ $t('components.bitbeli.widgetBenefitsCompanies.simplicityAndSecurity') }}</p>
    </div>
    <div flex flex-col gap-16px>
      <h3 heading6 font-700 flex flex-row justify-start items-center gap-8px>
        <UnoIcon class="i-fubex-bank" text-accent-500 dark:text-accent-300 h-24px w-24px /> {{ $t('components.bitbeli.widgetBenefitsCompanies.simplicityAndSecurity') }}
      </h3>
      <p>{{ $t('components.bitbeli.widgetBenefitsCompanies.simplicityAndSecurityDescription') }} </p>
    </div>
    <div flex flex-col gap-16px>
      <h3 heading6 font-700 flex flex-row justify-start items-center gap-8px>
        <UnoIcon class="i-fubex-credit-card" text-accent-500 dark:text-accent-300 h-24px w-24px /> {{ $t('components.bitbeli.widgetBenefitsCompanies.flexiblePayments') }}
      </h3>
      <p>{{ $t('components.bitbeli.widgetBenefitsCompanies.flexiblePaymentsDescription') }}</p>
    </div>
  </div>
</template>
