import validate from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.4.1_@types+node@22.10.7_db0@0.2.1_encoding@0.1.13_eslint@9.18.0_frbbxo3jfewr4zdilrlh6ln7ta/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45setup_45global from "/vercel/path0/layers/base/middleware/01.setup.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.4.1_@types+node@22.10.7_db0@0.2.1_encoding@0.1.13_eslint@9.18.0_frbbxo3jfewr4zdilrlh6ln7ta/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45setup_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  admin: () => import("/vercel/path0/layers/modules/fubex-auth/middleware/admin.ts"),
  auth: () => import("/vercel/path0/layers/modules/fubex-auth/middleware/auth.ts"),
  inspector: () => import("/vercel/path0/layers/modules/fubex-auth/middleware/inspector.ts")
}