<script lang="ts" setup>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Pagination } from 'vue3-carousel'

const logos = [
  {
    title: 'Anomia',
    src: '/img/partners/01-anomia.webp',
  },
  {
    title: 'Auto z dovozu',
    src: '/img/partners/02-autozdovozu.svg',
  },
  {
    title: 'Future Mining',
    src: '/img/partners/03-future-mining.png',
  },
  {
    title: 'Jetbee',
    src: '/img/partners/04-jetbee.svg',
  },
  {
    title: 'Aktiv Reality',
    src: '/img/partners/06-aktivreality.svg',
  },
  {
    title: 'Heliczech',
    src: '/img/partners/07-heliczech.png',
  },
  {
    title: 'Woodmay',
    src: '/img/partners/08-woodmay.svg',
  },
  {
    title: 'Románek a partneři',
    src: '/img/partners/10-romanek.jpg',
  },
  {
    title: 'Guard Capital',
    src: '/img/partners/11-guard-capital.png',
  },
  {
    title: 'TOP Drive club',
    src: '/img/partners/12-top-drive-club.svg',
  },
  {
    title: 'SuperCarService',
    src: '/img/partners/13-supercar-service.png',
  },
  {
    title: 'daloo',
    src: '/img/partners/14-daloo.png',
  },
  {
    title: 'Simix Motors',
    src: '/img/partners/15-simix-motors.png',
  },
  {
    title: 'Cirrus Aircraft',
    src: '/img/partners/15-cirrus-aircraft.svg',
  },
  {
    title: 'Damacom',
    src: '/img/partners/16-damacom.png',
  },
  {
    title: 'Alutech Bohemia',
    src: '/img/partners/17-alutech.png',
  },
]

const breakpoints = {
  // 0px and up
  0: {
    itemsToShow: 2,
    snapAlign: 'start',
  },
  // 700px and up
  700: {
    itemsToShow: 3,
    snapAlign: 'start',
  },
  // 1024 and up
  1024: {
    itemsToShow: 4,
    snapAlign: 'start',
  },
  // 1280 and up
  1280: {
    itemsToShow: 6,
    snapAlign: 'start',
  },
}
</script>

<template>
  <ClientOnly>
    <Carousel :wrap-around="true" :autoplay="1500" :transition="500" :pause-autoplay-on-hover="true" :breakpoints="breakpoints" w-full cursor-grab>
      <Slide v-for="logo in logos" :key="logo.title" h-100px>
        <div px-8px w-full h-full>
          <div
            flex items-center justify-center
            bg-white border-1 border-neutral-500 w-full h-full fubex-rounded-lg
          >
            <NuxtImg placeholder :src="logo.src" max-w="90%" max-h="50%" :alt="logo.title" />
          </div>
        </div>
      </Slide>

      <template #addons>
        <Pagination />
      </template>
    </Carousel>
  </ClientOnly>
</template>

<style>
.dark button.carousel__pagination-button:after{
  background-color: #666 !important;
}
.dark button.carousel__pagination-button--active:after{
  background-color: #ccc !important;
}
</style>
