<script lang="ts" setup>
useHead({ title: '' })

const [individualsHovered, toggleIndividualsHovered] = useToggle(false)

const productsSection = ref()
const contactSection = ref()

function scrollToProducts () {
  productsSection.value.scrollIntoView({ behavior: 'smooth' })
}
function scrollToContact () {
  contactSection.value.scrollIntoView({ behavior: 'smooth' })
}
</script>

<template>
  <div>
    <!-- hero section -->
    <div bg-gradient-to-br dark:bg-gradient-to-b from="primary-200 dark:neutral-50" via-primary-50 dark="via-50% via-primary-50" to="primary-100 dark:neutral-100" min-h-500px>
      <div fubex-container py-28px>
        <div bg="primary-200/50 dark:primary-100/30" w-fit mx-auto flex flex-row justify-center items-center gap-8px fubex-rounded-lg px-8px md:px-12px py-4px md:py-8px my-16px>
          <div :class="individualsHovered ? 'text-black dark:text-white' : 'bg-primary-500 text-white dark:bg-primary-300'" py-8px px-16px fubex-rounded-md cursor-default duration-350>
            <span hidden sm:block>{{ $t('components.bitbeli.index.solutionsForCompanies') }}</span>
            <span block sm:hidden>{{ $t('components.bitbeli.index.companies') }}</span>
          </div>
          <div>
            <I18nLink to="/smena-kryptomen" hover="bg-accent-500 dark:bg-accent-300 text-black" py-8px px-16px fubex-rounded-md block duration-350 @mouseenter="toggleIndividualsHovered()" @mouseleave="toggleIndividualsHovered()">
              <span hidden sm:block>{{ $t('components.bitbeli.index.servicesForIndividuals') }}</span>
              <span block sm:hidden>{{ $t('components.bitbeli.index.individuals') }}</span>
            </I18nLink>
          </div>
        </div>
        <div flex flex-row items-center>
          <div flex flex-col gap-40px my-40px>
            <h1 display-sm font-700 text-center lg:text-left>
              {{ $t('components.bitbeli.index.comprehensiveSolutions') }}
            </h1>
            <div block lg:hidden min-w="130% sm:80%">
              <NuxtImg src="/img/homepage-img.webp" placeholder w-full />
            </div>
            <p v-html="$t('components.bitbeli.index.invoiceInCrypto')" />
            <div flex flex-col sm:flex-row gap-x-8px gap-y-0px text-24px font-500 self-center lg:self-start>
              <div flex items-center gap-8px hover:bg-primary-100 rounded-lg>
                <UnoIcon i-fubex-stopwatch inline-block text-accent-500 dark:text-accent-300 h-30px w-30px />{{ $t('components.bitbeli.index.quickly') }}
              </div>
              <div flex items-center gap-8px hover:bg-primary-100 rounded-lg>
                <UnoIcon i-fubex-lightning inline-block text-accent-500 dark:text-accent-300 h-28px w-28px mx-3px />{{ $t('components.bitbeli.index.simply') }}
              </div>
              <div flex items-center gap-8px hover:bg-primary-100 rounded-lg>
                <UnoIcon i-fubex-safe inline-block text-accent-500 dark:text-accent-300 h-26px w-26px mx-1px />{{ $t('components.bitbeli.index.safely') }}
              </div>
            </div>
            <div w-fit self-center lg:self-start>
              <FButton name="services" size="lg" suffix-icon="i-fubex-arrow-down-circle" @click="scrollToProducts">
                {{ $t('components.bitbeli.index.ourProducts') }}
              </FButton>
            </div>
          </div>
          <div hidden lg:block min-w="60%">
            <NuxtImg src="/img/homepage-img.webp" w-full />
          </div>
        </div>
      </div>
    </div>
    <!-- /hero section -->

    <!-- partner logos carousel -->
    <div my-100px flex flex-col gap-32px>
      <h2 paragraph-md text-neutral-400 font-700 text-center uppercase>
        {{ $t('components.bitbeli.index.ourPartners') }}
      </h2>
      <WidgetPartnerCarousel />
    </div>
    <!-- /partner logos carousel -->

    <!-- company verification -->
    <div fubex-container my-100px>
      <h2 heading1 font-700 text-center>
        {{ $t('components.bitbeli.index.accountSetup') }}
      </h2>
      <p text-center max-w-3xl mx-auto my-20px>
        {{ $t('components.bitbeli.index.easyAccountSetup') }}
      </p>
      <WidgetBenefitsCompanies />
    </div>
    <!-- /company verification -->

    <!-- our products -->
    <div ref="productsSection" fubex-container my-100px flex flex-col gap-32px pt-40px>
      <h2 paragraph-md text-neutral-400 font-700 text-center uppercase>
        {{ $t('components.bitbeli.index.ourProducts') }}
      </h2>
      <div grid grid-cols-1 md:grid-cols-2 items-center>
        <div flex flex-col gap-20px z-10>
          <h2 heading2 font-700>
            {{ $t('components.bitbeli.index.purchaseCryptoForCompany') }}
          </h2>

          <p> {{ $t('components.bitbeli.index.buyAndSell') }} </p>
          <h3 heading6 font-700>
            {{ $t('components.bitbeli.index.purchaseCryptoBenefits') }}
          </h3>
          <ul flex flex-col gap-8px>
            <LiWithCheck>
              {{ $t('components.bitbeli.index.inflationProtection') }}
            </LiWithCheck>
            <LiWithCheck>
              {{ $t('components.bitbeli.index.riskReduction') }}
            </LiWithCheck>
            <LiWithCheck>
              {{ $t('components.bitbeli.index.modernTechnologies') }}
            </LiWithCheck>
          </ul>
          <div flex flex-col sm:flex-row items-center gap-24px my="20px">
            <WidgetAuthDialogs default-view="register">
              <FButton name="create-account" size="lg">
                {{ $t('components.bitbeli.index.createAccount') }}
              </FButton>
            </WidgetAuthDialogs>
            <I18nLink to="/nakup-kryptomen-na-firmu" basic-link>
              {{ $t('components.bitbeli.index.learnMore') }}
            </I18nLink>
          </div>
        </div>
        <div relative>
          <Ellipse />
          <NuxtImg src="/img/macbook.webp" w-full z-10 relative />
        </div>
      </div>
    </div>
    <!-- /our products -->

    <!-- crypto payments -->
    <div fubex-container my-100px flex flex-col gap-32px>
      <div grid grid-cols-1 md:grid-cols-2 items-center>
        <div flex flex-col gap-20px z-10>
          <h2 heading2 font-700>
            {{ $t('components.bitbeli.index.cryptoInvoicePayment') }}
          </h2>
          <p>{{ $t('components.bitbeli.index.acceptCryptoPayments') }}</p>
          <h3 heading6 font-700>
            {{ $t('components.bitbeli.index.whyAcceptCrypto') }}
          </h3>
          <ul flex flex-col gap-8px>
            <LiWithCheck>{{ $t('components.bitbeli.index.competitiveAdvantage') }}</LiWithCheck>
            <LiWithCheck>{{ $t('components.bitbeli.index.brandImage') }}</LiWithCheck>
            <LiWithCheck>{{ $t('components.bitbeli.index.borderlessPayments') }}</LiWithCheck>
          </ul>
          <div flex flex-col sm:flex-row items-center gap-24px my="20px">
            <WidgetAuthDialogs default-view="register">
              <FButton name="create-account" size="lg">
                {{ $t('components.bitbeli.index.createAccount') }}
              </FButton>
            </WidgetAuthDialogs>
            <I18nLink to="/platba-faktur-kryptomenami" basic-link>
              {{ $t('components.bitbeli.index.learnMore') }}
            </I18nLink>
          </div>
        </div>
        <div relative md:order-first w="90%" h-full pt-40px>
          <Ellipse />
          <NuxtImg src="/img/macbook-2.webp" w-full z-10 relative />
        </div>
      </div>
    </div>
    <!-- /crypto payments -->

    <!-- newsletter -->
    <div id="subscribe" :data-test="getCypressId('newsletter')" fubex-container p-16px mb-48px relative z-10>
      <WidgetNewsletter
        request-type="document"
        title="components.bitbeli.index.newsletterTitle"
        text="components.bitbeli.index.newsletterText"
        toast-text="components.bitbeli.index.newsletterToast"
        button-title="components.bitbeli.index.download"
      />
    </div>
    <!-- /newsletter -->

    <!-- whitelabel software -->
    <div bg-neutral-50 border-t-1 border-neutral-200 mt-134px>
      <div fubex-container flex flex-col gap-32px relative py-80px>
        <div grid grid-cols-1 md:grid-cols-2 items-center>
          <div flex flex-col gap-20px z-10>
            <h2 heading2 font-700>
              {{ $t('components.bitbeli.index.whitelabelSoftware') }}
            </h2>
            <p>{{ $t('components.bitbeli.index.whitelabelSoftwareText') }} </p>

            <ul flex flex-col gap-8px>
              <LiWithCheck>
                {{ $t('components.bitbeli.index.whitelabelSoftwareBenefits') }}
              </LiWithCheck>
              <LiWithCheck>
                {{ $t('components.bitbeli.index.whitelabelSoftwareSupport') }}
              </LiWithCheck>
              <LiWithCheck>
                {{ $t('components.bitbeli.index.whitelabelSoftwareDeployment') }}
              </LiWithCheck>
            </ul>
            <div flex flex-col md:flex-row items-center gap-24px my="20px">
              <FButton name="create-account" size="lg" @click="scrollToContact()">
                {{ $t('components.bitbeli.index.interestedInSoftware') }}
              </FButton>
              <I18nLink to="/smenarensky-software" basic-link>
                {{ $t('components.bitbeli.index.moreAboutSoftware') }}
              </I18nLink>
            </div>
          </div>
          <div relative>
            <Ellipse />
            <NuxtImg w-full src="/img/tablet-tower.webp" z-10 relative />
          </div>
        </div>
      </div>
    </div>
    <!-- /whitelabel software -->

    <!-- where is software used -->
    <div bg-neutral-50 border-t-1 border-neutral-200>
      <div fubex-container py-80px flex flex-col gap-80px>
        <h2 heading2 font-700 text-center>
          {{ $t('components.bitbeli.index.satisfiedClients') }}
        </h2>
        <WidgetReferencesCarousel @scroll-to-contact="scrollToContact()" />
      </div>
    </div>
    <!-- /where is software used -->

    <!-- contact form -->
    <div ref="contactSection">
      <WidgetContactForm>
        <h2 heading2 font-700 text-white>
          {{ $t('components.bitbeli.index.contactForm') }}
        </h2>
      </WidgetContactForm>
    </div>
    <!-- /contact form -->
  </div>
</template>
