<script lang="ts" setup>
import * as yup from 'yup'
import { useToast } from 'vue-toastification'

export type ContactVariant = 'primary' | 'accent'

interface ContactProps {
  instructions?: string
  variant?: ContactVariant
  imageSrc?: string
}

withDefaults(defineProps<ContactProps>(), {
  variant: 'accent',
  imageSrc: '/img/bitcoin-bag.webp',
})

const { emailValidation, phoneValidation } = useValidations()

const { platform } = useAppConfig()

const toast = useToast()
const { $t } = useI18n()

const validationSchema = yup.object({
  name: yup.string().required(String($t('components.bitbeli.widgetContactForm.nameRequired'))).min(3, String($t('components.bitbeli.widgetContactForm.nameMinLength'))),
  customerEmail: emailValidation,
  phoneNumber: phoneValidation,
  message: yup.string().required(String($t('components.bitbeli.widgetContactForm.messageRequired'))),
  agree: yup.boolean().oneOf([true], String($t('components.bitbeli.widgetContactForm.agreeRequired'))).required(),
})

const { meta, resetForm, handleSubmit, isSubmitting } = useForm({
  validationSchema,
})

const onSubmit = handleSubmit(async values => {
  try {
    await useFetch('/api/form/contact', {
      method: 'POST',
      body: {
        name: values.name,
        customerEmail: values.customerEmail,
        phoneNumber: values.phoneNumber,
        message: values.message,
      },
    })
    toast.success(String($t('components.bitbeli.widgetContactForm.messageSent')))
    resetForm()
  }
  catch (e) {
    sentryCaptureException(e)
    toast.error(String($t('components.bitbeli.widgetContactForm.messageError')))
  }
})
</script>

<template>
  <div
    py-80px
    :class="[
      variant === 'primary' ? 'bg-primary-500 dark:bg-primary-50' : '',
      variant === 'accent' ? 'bg-accent-500 dark:bg-neutral-200/40' : '',
    ]"
  >
    <div :data-test="getCypressId('contact-form')" fubex-container flex gap-40px xl:gap-120px justify-center items-center>
      <div hidden lg:flex justify-center shrink>
        <NuxtImg :src="imageSrc" />
      </div>
      <div flex flex-col gap-40px md:flex-none>
        <div>
          <slot />
        </div>
        <div v-if="instructions" paragraph-md text-neutral-900 dark:text-primary-10>
          {{ instructions }}
        </div>
        <form @submit="onSubmit">
          <div flex flex-col gap-24px>
            <div grid grid-cols-1 md:grid-cols-3 gap-16px>
              <FInputVee
                name="name"
                type="text"
                :label="String($t('components.bitbeli.widgetContactForm.nameLabel'))"
                :variant="variant === 'accent' ? 'on-accent-bg' : 'on-primary-bg'"
                :is-required="true"
              />
              <FInputVee
                name="customerEmail"
                type="text"
                :label="String($t('components.bitbeli.widgetContactForm.emailLabel'))"
                :variant="variant === 'accent' ? 'on-accent-bg' : 'on-primary-bg'"
                :is-required="true"
              />
              <FInputVee
                name="phoneNumber"
                type="text"
                :label="String($t('components.bitbeli.widgetContactForm.phoneLabel'))"
                :variant="variant === 'accent' ? 'on-accent-bg' : 'on-primary-bg'"
                :is-required="true"
              />
            </div>
            <FTextareaVee
              name="message"
              :label="String($t('components.bitbeli.widgetContactForm.messageLabel'))"
              :color-variant="variant === 'accent' ? 'on-accent-bg' : 'on-primary-bg'"
              :is-required="true"
            />

            <FCheckboxVee
              name="agree"
              my-16px
              :color-variant="variant === 'accent' ? 'on-accent-bg' : 'on-primary-bg'"
            >
              {{ $t('components.bitbeli.widgetContactForm.agreeText') }} <NuxtLink :to="`${platform.domain}/docs/gdpr.pdf`" target="_blank" basic-link tabindex="-1" @click.stop>
                {{ $t('components.bitbeli.widgetContactForm.personalData') }}
              </NuxtLink>.
            </FCheckboxVee>
            <div flex paragraph-md gap-8px items-center font-500 text-neutral-500>
              <FButton
                name="contact-send" :disabled="!meta.valid" :loading="isSubmitting" size="lg" w-full type="submit"
                :variant="variant === 'accent' ? 'on-accent-bg' : 'on-primary-bg'"
              >
                {{ $t('components.bitbeli.widgetContactForm.sendMessage') }}
              </FButton>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
