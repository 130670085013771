<script setup lang="ts">
import * as yup from 'yup'
import { useToast } from 'vue-toastification'
import type { AuthView } from '@base/types'

const emit = defineEmits<{
  (e: 'changeView', view: AuthView): void
  (e: 'close'): void
}>()

const { $t, $switchRoute } = useI18n()
type SignUpView = 'individual' | 'company'
const selectedTab = ref<SignUpView>('individual')
const tabs: { title: string, type: SignUpView }[] = [
  {
    title: String($t('auth.individualTab')),
    type: 'individual',
  },
  {
    title: String($t('auth.companyTab')),
    type: 'company',
  },
]

const toast = useToast()
const { registerWithPassword } = useAuth()
const { emailValidation, passwordValidation, companyIdNumberValidation } = useValidations()
const { platform } = useAppConfig()

const validationSchema = yup.object({
  name: yup.string().required(String($t('auth.nameRequired'))).min(5, String($t('auth.nameMin'))),
  email: emailValidation,
  password: passwordValidation,
  consent: yup.boolean().required().oneOf([true], String($t('auth.consentRequired'))),
  isCompany: yup.boolean(),
  companyIdNumber: yup.string().when(
    'isCompany',
    {
      is: true,
      then: () => companyIdNumberValidation,
    },
  ),
  companyName: yup.string().when(
    'isCompany',
    {
      is: true,
      then: () => yup.string().required(String($t('auth.companyIdRequired'))).min(6, String($t('auth.consentIdMin'))),
    },
  ),
})

const { meta, setFieldValue, isSubmitting, handleSubmit } = useForm({ validationSchema })

watchEffect(() => {
  setFieldValue('isCompany', selectedTab.value === 'company')
})

const onSubmit = handleSubmit(async values => {
  const error = await registerWithPassword({
    email: values.email,
    password: values.password,
  },
  {
    name: values.name,
    isCompany: values.isCompany,
    companyIdNumber: values.companyIdNumber,
    companyName: values.companyName,
    platformId: platform.id,
    invitedByUserId: useCookie('bitbeli-invitedByUserId').value,
  })

  if (error)
    return toast.error(String($t('auth.emailAlreadyExists')))

  emit('close')
  $switchRoute('/signup-success')
})
</script>

<template>
  <h4 :data-test="getCypressId('sign-up-header')" heading4 font-800 my-16px>
    {{ $t('auth.signUpHeader') }}
  </h4>
  <div>
    <div
      pb-0px flex-wrap flex
      gap-4px
      fubex-rounded-md text-left
      font-400 paragraph-sm text-neutral-900
      w-full justify-start items-start
      rounded-b-0 mb-16px
      border-b-3px border-primary-500 dark:border-primary-300
    >
      <div v-for="tab in tabs" :key="tab.type" w-fit>
        <h2
          p-6px px-12px md:py-12px md:px-16px fubex-rounded-md transition-all cursor-pointer duration-250 rounded-b-0
          :class="selectedTab === tab.type
            ? 'tab-active'
            : 'bg-neutral-100 dark:bg-neutral-200 hover:bg-neutral-200 dark:hover:bg-neutral-300' "
          @click="selectedTab = tab.type"
        >
          {{ tab.type === 'individual' ? $t('auth.individualTab') : $t('auth.companyTab') }}
        </h2>
      </div>
    </div>
    <form @submit="onSubmit">
      <div v-auto-animate flex flex-col gap-16px>
        <FInputVee v-if="selectedTab === 'company'" name="companyName" cypress-prefix="signup" type="text" :label="String($t('auth.companyName'))" :placeholder="String($t('auth.companyNamePlaceholder'))" />
        <FInputVee v-if="selectedTab === 'company'" name="companyIdNumber" cypress-prefix="signup" type="text" :label="String($t('auth.companyId'))" :placeholder="String($t('auth.companyIdPlaceholder'))" />
        <FInputVee name="name" cypress-prefix="signup" type="text" :label="selectedTab === 'individual' ? String($t('auth.name')) : String($t('auth.companyName'))" :placeholder="selectedTab === 'individual' ? String($t('auth.individualName')) : String($t('auth.companyNamePlaceholder'))" />
        <FInputVee name="email" type="text" :label="String($t('auth.emailLabel'))" :placeholder="String($t('auth.emailPlaceholder'))" />
        <FInputVee name="password" type="password" :label="String($t('auth.passwordLabel'))" :placeholder="String($t('auth.passwordPlaceholder'))" />
      </div>
      <div mt-40px flex>
        <FCheckboxVee name="consent">
          {{ $t('auth.consent') }}
          <NuxtLink :to="`${platform.domain}/docs/vop.pdf`" target="_blank" basic-link tabindex="-1" @click.stop>
            {{ $t('auth.vop') }}
          </NuxtLink>
          {{ $t('auth.vop2') }}
          <NuxtLink :to="`${platform.domain}/docs/gdpr.pdf`" target="_blank" basic-link tabindex="-1" @click.stop>
            {{ $t('auth.vop3') }}
          </NuxtLink>.
        </FCheckboxVee>
      </div>
      <FButton name="signup-register" :disabled="!meta.dirty || !meta.valid" variant="primary" size="lg" w-full mt-48px :loading="isSubmitting" type="submit">
        {{ $t('auth.submitButton') }}
      </FButton>
    </form>

    <div mt-32px paragraph-md text-center>
      {{ $t('auth.ifAccountExist') }}<br><button type="button" golden-link @click="emit('changeView', 'login')">
        {{ $t('auth.loginButton') }}
      </button>.
    </div>
  </div>
</template>
