<script lang="ts" setup>
export type TextareaVariant = 'standard' | 'container'
export type ColorVariant = 'standard' | 'on-primary-bg' | 'on-accent-bg'

export interface TextareaProps {
  name: string
  variant?: TextareaVariant
  colorVariant?: ColorVariant
  label?: string
  hint?: string
  placeholder?: string
  tooltip?: string
  isRequired?: boolean
  readOnly?: boolean
  modelValue?: any
  error?: string
  disabled?: boolean
  cypressPrefix?: string
  rows?: number
}

const props = withDefaults(defineProps<TextareaProps>(), {
  variant: 'standard',
  colorVariant: 'standard',
  disabled: false,
  rows: 6,
})

const emit = defineEmits(['update:modelValue', 'blur'])

if (!props.name)
  throw createError(`name is required (${props.label})`)

const modelValue = useVModel(props, 'modelValue', emit)

const input = ref()

const cypressName = computed(() => {
  return props.cypressPrefix ? `${props.cypressPrefix}-${props.name}` : props.name
})
</script>

<template>
  <div v-auto-animate flex flex-col gap-6px w-full>
    <label v-if="label && variant === 'standard'" :for="name" paragraph-md font-600 :class="['on-primary-bg', 'on-accent-bg'].includes(colorVariant) ? 'text-black dark:text-white' : ''">
      {{ label }}
      <span text-error-default>{{ isRequired ? '*' : '' }}</span>
    </label>
    <div
      px-16px py-12px flex flex-col gap-4px border="1px solid" fubex-rounded-lg
      :class="[
        error ? 'border-error-default' : 'focus-within:fubex-focus-ring',
        disabled ? 'opacity-80 cursor-not-allowed bg-neutral-300!' : '',
        colorVariant === 'standard' ? 'bg-neutral-50 border-neutral-200' : '',
        colorVariant === 'on-primary-bg' ? 'bg-primary-400 border-primary-400 dark:bg-primary-100 dark:border-primary-100' : '',
        colorVariant === 'on-accent-bg' ? 'bg-accent-400 border-accent-400 dark:bg-neutral-200 dark:border-neutral-200' : '',
      ]"
      @click="input.focus()"
    >
      <h2 v-if="label && variant === 'container'" paragraph-md font-600>
        {{ label }}
        <span text-error-default>{{ isRequired ? '*' : '' }}</span>
      </h2>
      <div flex items-center justify-between gap-8px>
        <div flex items-center gap-8px w-full>
          <template v-if="!!$slots.prefix">
            <slot v-if="!!$slots.prefix" name="prefix" />
            <div h-16px w-1px border="1px solid" />
          </template>
          <textarea
            ref="input"
            v-model="modelValue"
            :data-test="getCypressId(cypressName)"
            :disabled="disabled"
            :readonly="readOnly"
            :name="name"
            :placeholder="placeholder"
            :rows="rows"
            bg-transparent paragraph-md focus:outline-none
            :class="[
              colorVariant === 'on-primary-bg' ? 'text-neutral-100' : '',
              colorVariant === 'on-accent-bg' ? 'text-neutral-900' : '',
            ]"
            w-full @blur="emit('blur')"
          />
          <UnoIcon v-if="disabled" i-fubex-lock />
        </div>
        <UnoIcon v-if="error" i-carbon-warning w-16px h-16px text-error-default />
        <div v-else-if="tooltip || !!$slots.suffix" flex gap-8px>
          <FTooltip v-if="tooltip" :text="tooltip">
            <UnoIcon i-carbon-help w-16px h-16px />
          </FTooltip>
          <template v-if="!!$slots.suffix ">
            <slot v-if="!!$slots.suffix" name="suffix" />
          </template>
        </div>
      </div>
    </div>
    <div v-if="error" :data-test="getCypressId('login-error')" text-error-default paragraph-md>
      {{ error.includes('__key__') ? $t(error.replace('__key__', '')) : error }}
    </div>
    <p v-if="!error && hint" paragraph-md :class="[error ? 'text-error-default' : 'text-neutral-900']">
      {{ hint }}
    </p>
  </div>
</template>
