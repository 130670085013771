import revive_payload_client_kjoEyUE4RE from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.4.1_@types+node@22.10.7_db0@0.2.1_encoding@0.1.13_eslint@9.18.0_frbbxo3jfewr4zdilrlh6ln7ta/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_XEmSKPcaJJ from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.4.1_@types+node@22.10.7_db0@0.2.1_encoding@0.1.13_eslint@9.18.0_frbbxo3jfewr4zdilrlh6ln7ta/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_zh7cc63iL8 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.4.1_@types+node@22.10.7_db0@0.2.1_encoding@0.1.13_eslint@9.18.0_frbbxo3jfewr4zdilrlh6ln7ta/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import sentry_client_TfO2G4ijBm from "/vercel/path0/node_modules/.pnpm/nuxt-sentry@0.4.2_encoding@0.1.13_magicast@0.3.5_rollup@4.31.0_vue@3.5.13_typescript@5.7.3_/node_modules/nuxt-sentry/dist/runtime/sentry.client.mjs";
import supabase_client_4U5WcJ3hON from "/vercel/path0/node_modules/.pnpm/@nuxtjs+supabase@1.2.2_magicast@0.3.5_rollup@4.31.0/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.mjs";
import _0_siteConfig_r4kjbdLmGH from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_rollup@4.31.0_vite@6.0.9_@types+node@22.10.7_jiti@2.4.2_rp356ajjuvfbd4xef6gopxeiga/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import navigation_repaint_client_GCCrAtKDId from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.4.1_@types+node@22.10.7_db0@0.2.1_encoding@0.1.13_eslint@9.18.0_frbbxo3jfewr4zdilrlh6ln7ta/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_GAX9kQGNuS from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.4.1_@types+node@22.10.7_db0@0.2.1_encoding@0.1.13_eslint@9.18.0_frbbxo3jfewr4zdilrlh6ln7ta/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_rPJRizYIvC from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.4.1_@types+node@22.10.7_db0@0.2.1_encoding@0.1.13_eslint@9.18.0_frbbxo3jfewr4zdilrlh6ln7ta/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_TGo0N52PJJ from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.31.0_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/taurex/.nuxt/components.plugin.mjs";
import prefetch_client_35kv0TYBpH from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.4.1_@types+node@22.10.7_db0@0.2.1_encoding@0.1.13_eslint@9.18.0_frbbxo3jfewr4zdilrlh6ln7ta/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_Wi8CZr4RFk from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.21.0_change-case@5.4.4_focus-trap@7.5.4_magicast@0.3.5_qrcode@1.5.3_rollup@4.31.0__4inshrtlv5nc4iagcogzvm3c2q/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_u0PfR9GaIQ from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.21.0_change-case@5.4.4_focus-trap@7.5.4_magicast@0.3.5_qrcode@1.5.3_rollup@4.31.0__4inshrtlv5nc4iagcogzvm3c2q/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_Di5P9rqRvj from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.21.0_change-case@5.4.4_focus-trap@7.5.4_magicast@0.3.5_qrcode@1.5.3_rollup@4.31.0__4inshrtlv5nc4iagcogzvm3c2q/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_7L20aHFBzU from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.31.0_vite@6.0.9_@types+node@22.10.7_jiti@2.4.2_ters_5ub4vg7urg6x7z6zed5j7gglce/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_client_Y20vxbNpLD from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.31.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import unocss_MzCDxu9LMj from "/vercel/path0/apps/taurex/.nuxt/unocss.mjs";
import plugin_q6eYBBB7iQ from "/vercel/path0/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.15.2_@parcel+watcher@2.4.1_@types+node_d7q67ablc4mm3jx7cjitwk5q4i/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_VcClaeogLk from "/vercel/path0/node_modules/.pnpm/@dargmuesli+nuxt-cookie-control@8.5.0_magicast@0.3.5_rollup@4.31.0_webpack@5.91.0_esbuild@0.24.2_/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.js";
import bottomSheet_client_C55D02kKcz from "/vercel/path0/layers/components/fubex-components/plugins/bottomSheet.client.ts";
import validation_H6BESNSSPe from "/vercel/path0/layers/components/fubex-components/plugins/validation.ts";
import vue_toastification_client_OdKXnwPIgP from "/vercel/path0/layers/components/fubex-components/plugins/vue-toastification.client.ts";
import auto_animate_XIZCskOXXg from "/vercel/path0/layers/base/plugins/auto-animate.ts";
import chart_client_bcfhrmN7hg from "/vercel/path0/layers/base/plugins/chart.client.ts";
import use_head_sEf1F6ycmZ from "/vercel/path0/layers/base/plugins/use-head.ts";
import _01_plugin_k5yY0eR9Zj from "/vercel/path0/node_modules/.pnpm/nuxt-i18n-micro@1.68.0_magicast@0.3.5_rollup@4.31.0_vite@6.0.9_@types+node@22.10.7_jiti@2.4.2_o34kfrrl35o2nj7ndjl6ynlum4/node_modules/nuxt-i18n-micro/dist/runtime/plugins/01.plugin.js";
import _03_define_TygWFKH7jH from "/vercel/path0/node_modules/.pnpm/nuxt-i18n-micro@1.68.0_magicast@0.3.5_rollup@4.31.0_vite@6.0.9_@types+node@22.10.7_jiti@2.4.2_o34kfrrl35o2nj7ndjl6ynlum4/node_modules/nuxt-i18n-micro/dist/runtime/plugins/03.define.js";
export default [
  revive_payload_client_kjoEyUE4RE,
  unhead_XEmSKPcaJJ,
  router_zh7cc63iL8,
  sentry_client_TfO2G4ijBm,
  supabase_client_4U5WcJ3hON,
  _0_siteConfig_r4kjbdLmGH,
  navigation_repaint_client_GCCrAtKDId,
  check_outdated_build_client_GAX9kQGNuS,
  chunk_reload_client_rPJRizYIvC,
  plugin_vue3_TGo0N52PJJ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_35kv0TYBpH,
  slideovers_Wi8CZr4RFk,
  modals_u0PfR9GaIQ,
  colors_Di5P9rqRvj,
  plugin_7L20aHFBzU,
  plugin_client_Y20vxbNpLD,
  unocss_MzCDxu9LMj,
  plugin_q6eYBBB7iQ,
  plugin_VcClaeogLk,
  bottomSheet_client_C55D02kKcz,
  validation_H6BESNSSPe,
  vue_toastification_client_OdKXnwPIgP,
  auto_animate_XIZCskOXXg,
  chart_client_bcfhrmN7hg,
  use_head_sEf1F6ycmZ,
  _01_plugin_k5yY0eR9Zj,
  _03_define_TygWFKH7jH
]