interface NavigationItem {
  label: string
  key: string
  trkey: string
  href: string
  subMenu?: SubMenuItem[]
}

interface SubMenuItem {
  label: string
  href: string
  key: string
  trkey?: string
}
const navigation: NavigationItem[] = reactive([
  {
    label: 'Pro firmy',
    key: 'pro-firmy',
    trkey: 'forCompanies',
    href: '/',
    subMenu: [
      { label: 'Platba faktur kryptoměnami', href: '/platba-faktur-kryptomenami', key: 'invoice', trkey: 'invoice' },
      { label: 'Nákup kryptoměn na firmu', href: '/nakup-kryptomen-na-firmu', key: 'company', trkey: 'purchaseCryptoForCompany' },
      { label: 'Směnárenský software', href: '/smenarensky-software', key: 'whitelabel', trkey: 'whitelabel' },
    ],
  },
  {
    label: 'Pro jednotlivce',
    key: 'pro-jednotlivce',
    trkey: 'forIndividuals',
    href: '/',
    subMenu: [
      { label: 'Směna kryptoměn', href: '/smena-kryptomen', key: 'smena-kryptomen', trkey: 'cryptoExchange' },
      { label: 'Pravidelné spoření', href: '/smena-kryptomen#pravidelne-sporeni', key: 'pravidelne-sporeni', trkey: 'regularSavings' },
      { label: 'Affiliate', href: '/affiliate', key: 'affiliate', trkey: 'affiliate' },
    ],
  },
  {
    label: 'Společnost',
    key: 'spolecnost',
    trkey: 'company',
    href: '/',
    subMenu: [
      { label: 'O nás', href: '/o-nas', key: 'about', trkey: 'about' },
      { label: 'Nejčastější otázky', href: '/faq', key: 'faq', trkey: 'faq' },
      { label: 'Kurzovní lístek', href: '/kurzovni-listek', key: 'kurzovni-listek', trkey: 'ticket' },
      { label: 'Blog', href: '/blog', key: 'blog', trkey: 'blog' },
      { label: 'Kontakty', href: '/kontakt', key: 'kontakt', trkey: 'contact' },
    ],
  },
  {
    label: 'O kryptoměnách',
    key: 'o-kryptomenach',
    trkey: 'aboutCrypto',
    href: '/o-kryptomenach',
  },
])

const profileNavigation = [
  {
    label: 'Přehled',
    key: 'profile-overview',
    trkey: 'profileOverview',
    href: '/profile/overview',
    icon: 'i-fubex-square-menu',
  },
  {
    label: 'Směna',
    key: 'profile-exchange',
    trkey: 'profileExchange',
    href: '/profile/exchange',
    icon: 'i-fubex-exchange',
  },
  {
    label: 'Historie směn',
    key: 'profile-history',
    trkey: 'profileHistory',
    href: '/profile/history',
    icon: 'i-fubex-history',
  },
  {
    label: 'Můj účet',
    href: '/profile/account',
    key: 'profile',
    trkey: 'profile',
    icon: 'i-fubex-settings',
  },
  {
    label: 'Bonusový program',
    key: 'profile-bonus',
    trkey: 'profileBonus',
    href: '/profile/bonus',
    icon: 'i-fubex-bonus',
  },
  {
    label: 'Odhlásit se',
    key: 'logout',
    trkey: 'logout',
    href: '/logout',
    icon: 'i-fubex-logout',
  },

]
const mobileMenuOpen = ref(false)

export default function useNavigation () {
  const getNavigation = async () => {
    const links = await $fetch('/api/crypto/navigation-links')
    navigation.find(group => group.key === 'o-kryptomenach')!.subMenu = links

    return navigation
  }

  return {
    mobileMenuOpen,
    navigation,
    profileNavigation,
    getNavigation,
  }
}
